<template>
  <the-header></the-header>
  <router-view v-slot="slotProps">
      <component :is="slotProps.Component"></component>
  </router-view>
</template>

<script>
import TheHeader from './components/layout/TheHeader.vue';

export default {
  components: {
    TheHeader
  }  
}
</script>

<style>

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5 {
  color: #030b6d;
  text-align: center;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

p {
  font-size: 20px;
  color: black;
}
</style>
