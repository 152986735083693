<template>
  <button>
    <slot></slot>
  </button>
</template>

<script>
export default {
  emits: ['button-pressed'],
  methods: {
    buttonPressed() {
      this.$emit('button-pressed')
    }
  }
}
</script>

<style scoped>
button,
a {
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  font: inherit;
  background-color: #030b6d;
  border: 1px solid #030b6d;
  color: white;
  cursor: pointer;
  border-radius: 30px;
  margin-right: 0.5rem;
  display: inline-block;
}

a:hover,
a:active,
button:hover,
button:active {
  background-color: black;
  border-color: black;
}

</style>