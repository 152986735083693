
export default {
  namespaced: true,
  state() {
    return {
      currentApp: null,
      apps: [
        {
          id: 'tap-stacks',
          name: 'Tap Stacks',
          iconName: 'icon_tap-stacks.png',
          releaseYear: '2015',
          description:
            'Stack blocks! The higher you get the more difficult it gets. Can you stack 100?',
          isHidden: false,
          appStoreLink: 'https://apps.apple.com/us/app/tap-stacks/id1000466516'
        },
        {
          id: 'bombed',
          name: 'Bombed',
          iconName: 'icon_bombed.png',
          releaseYear: '2015',
          description:
            'Put your reflexes to the test in this explosive high speed puzzle game!',
          isHidden: false,
          appStoreLink: "https://apps.apple.com/us/app/bombed/id1036054479"
        },
        {
          id: 'gamemate',
          name: 'GameMate',
          releaseYear: '2017',
          iconName: 'icon_gamemate.png',
          description: 'Keep track of the score to any game! Interactive players can see and adjust scores.',
          isHidden: false,
          appStoreLink: 'https://apps.apple.com/us/app/game-mate/id1229804802'
        },
        {
          id: 'dashmate',
          name: 'DashMate',
          iconName: 'icon_dashmate.png',
          releaseYear: '2020',
          description:
            'Quickly find your street address and zip code!',
          isHidden: false,
          appStoreLink: "https://apps.apple.com/us/app/dashboard/id1525584990"
          },
        {
          id: 'soapstone',
          name: '...',
          releaseYear: '...',
          iconName: 'icon_soapstone.png',
          description: 'Something cool is in the works...',
          isHidden: true,
          appStoreLink: null
        }
      ]
    }
  },
  getters: {
    getApps(state) {
      return state.apps.filter(app => app.isHidden == false)
    }
  },
}