<template>
	<div class="policy-body">
		<h1>Privacy Policy - DashMate</h1>
		
		<div class="policy-section">
			<h3>Permissions Requested</h3>
			<p>
				The application requests explicit permission from the user before
				accessing any data. Users have the option to deny access,
				though it may impact the app experience.
			</p>
			<p>The following Apple permissions are requested upon login:</p>
			<ul>
				<li>
					<strong> Location Services: </strong>Allow DashMate to accurately determine user location and provide services accordingly. All DashMate users should accept this to get the most out of the app. 
				</li>
				<li>
					<strong>Ad Tracking Transparency: </strong>Allow DashMate to improve the relevance of ad content served. This is optional and will not greatly impact the app experience.
				</li>
			</ul>
		</div>

		<div class="policy-section">
			<h3>Data Collection</h3>
			<p>
				No additional data is collected other than that used by enabled Ad Tracking Transparency. Location Services are only used locally while DashMate is running.
			</p>
		</div>

		<h3>Contact Information</h3>
		<p><strong>Owner and data controller</strong></p>
		<p>Brook Street Games LLC</p>
		<p>1993 Foxglove Circle, Bellport NY 11713</p>
		<p>brookstgames@gmail.com</p>
	</div>
</template>

<style scoped>
* {
	font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif;
	text-align: left;
	color: black;
}

h1 {
	font-size: 32px;
	text-align: center;
}

h3 {
	font-size: 20px;
}

p,
li {
	font-size: 14px;
	color: #444444;
}

.policy-body {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem;
}
</style>