import { createRouter, createWebHistory } from 'vue-router'

import ApplicationsPage from '../pages/ApplicationsPage.vue'
import ApplicationDetailPage from '../pages/ApplicationDetailPage.vue'
import ContactPage from '../pages/ContactPage.vue'
import TapStacksPrivacyPolicyPage from '../pages/privacy-policy/TapStacksPrivacyPolicy.vue'
import BombedPrivacyPolicyPage from '../pages/privacy-policy/BombedPrivacyPolicy.vue'
import GameMatePrivacyPolicyPage from '../pages/privacy-policy/GameMatePrivacyPolicy.vue'
import DashMatePrivacyPolicyPage from '../pages/privacy-policy/DashMatePrivacyPolicy.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/applications' },
    { path: '/applications', component: ApplicationsPage },
    { path: '/applications/:id', component: ApplicationDetailPage, props: true },
    { path: '/applications/tap-stacks/privacy-policy', component: TapStacksPrivacyPolicyPage },
    { path: '/applications/bombed/privacy-policy', component: BombedPrivacyPolicyPage },
    { path: '/applications/gamemate/privacy-policy', component: GameMatePrivacyPolicyPage },
    { path: '/applications/dashmate/privacy-policy', component: DashMatePrivacyPolicyPage },
    { path: '/contact', component: ContactPage }
  ]
});

export default router;
