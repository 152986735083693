<template>
  <header>
    <nav>
      <h2 class ="home-logo">
        <router-link to="/">BSG</router-link>
      </h2>
      <ul>
        <li>
          <router-link to="/applications">Applications</router-link>
        </li>
        <li>
          <router-link to="/contact">Contact</router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<style scoped>

.home-logo {
  text-align: left;
  padding: 0
}

header {
  width: 100%;
  height: 5rem;
  background-color: #030b6d;
  display: flex;
  justify-content: center;
  align-items: center;
}

header a {
  text-decoration: none;
  color: white;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
}

a:active,
a.router-link-active {
  border-bottom: 2px solid white;
}

a:hover {
  color: rgba(255, 255, 255, 0.5);
}

h1 {
  margin: 0;
}

h1 a {
  color: white;
  margin: 0;
}

h1 a:hover,
h1 a:active,
h1 a.router-link-active {
  border-color: transparent;
}

header nav {
  width: 95%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 0.5rem;
}
</style>