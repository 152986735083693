<template>
	<img class="banner-image" src="../../assets/images/bsg-table.jpg" />
	<div class="content">
		<h1>Contact Us</h1>
		<p v-if="!emailSentSuccessfully">
			Have a question about our games? You are in the right place. Fill out the
			form with your name, email and a brief message and we will get back to you
			as soon as possible!
		</p>
		<p v-else>
			An email has been submitted. We will get back to you as soon as possible!
		</p>

		<!-- The name property for each input must match the one used in the EmailJS template -->
		<form v-if="!emailSentSuccessfully" @submit.prevent="submitForm">
			<div class="form-control">
				<label for="name">Name</label>
				<input type="text" name="sender_name" v-model.trim="name" />
				<p v-if="validationActive && !nameIsValid" class="error">
					Enter your name.
				</p>
			</div>
			<div class="form-control">
				<label for="email">Email</label>
				<input type="email" name="sender_email" v-model.trim="email" />
				<p v-if="validationActive && !emailIsValid" class="error">
					Enter a valid email address.
				</p>
			</div>
			<div class="form-control">
				<label for="message">Message</label>
				<textarea rows="5" name="message" v-model.trim="message"></textarea>
				<p v-if="validationActive && !messageIsValid" class="error">
					Enter a message.
				</p>
			</div>
			<div class="action">
				<base-button>Send Message</base-button>
			</div>
		</form>
	</div>
</template>

<script>
import emailjs from "emailjs-com";

export default {
	data() {
		return {
			name: "",
			email: "",
			message: "",
			validationActive: false,
			emailSentSuccessfully: false
		};
	},
	computed: {
		nameIsValid() {
			return this.name.length > 0;
		},
		emailIsValid() {
			// Used a single backslash to escape @ and . here. This is different from Swift where two are required because it is a string.
			const regularExpression =
				/[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]{1,64}\@[a-zA-Z0-9-]+\.[a-zA-Z]+/;
			return regularExpression.test(this.email);
		},
		messageIsValid() {
			return this.message.length > 0;
		},
		formIsValid() {
			return this.nameIsValid && this.emailIsValid && this.messageIsValid;
		},
	},
	methods: {
		submitForm(form) {
			this.validationActive = true;
			if (!this.formIsValid) {
				return;
			}

			emailjs
				.sendForm(
					"bsg_gmail",
					"bsg_contact",
					form.target,
					"user_k9izTYfZW7rf6sYbre1Dw"
				)
				.then(
					(result) => {
						this.emailSentSuccessfully = true
						console.log("SUCCESS", result.status, result.text);
					},
					(error) => {
						console.log("FAILED", error);
					}
				);
		},
	},
};
</script>

<style scoped>
.banner-image {
  width: 100%;
	min-width: 1000px;
  aspect-ratio: 5;
}
.content {
	width: 50%;
	min-width: 500px;
	margin: auto;
}
h1 {
	text-align: center;
	color: #030b6d;
}

form {
	margin-top: 5rem;
	border: 1px solid #ccc;
	border-radius: 12px;
	padding: 1rem;
}

.form-control {
	margin: 0.5rem 0;
}

label {
	font-weight: bold;
	margin-bottom: 0.5rem;
	display: block;
}

input,
textarea {
	display: block;
	width: 100%;
	font: inherit;
	border: 1px solid #ccc;
	padding: 0.15rem;
}

input:focus,
textarea:focus {
	border-color: #3d008d;
	background-color: #faf6ff;
	outline: none;
}

.error {
	font-weight: bold;
	color: red;
}

.action {
	text-align: center;
}
</style>