<template>
	<h1>Applications</h1>
	<ul>
		<li v-for="app in apps" :key="app.name">
			<app-tile :app="app" @tile-selected="showGameDetail"></app-tile>
		</li>
	</ul>
</template>

<script>
import AppTile from "../components/layout/AppTile.vue"

export default {
	components: {
		AppTile,
	},
	computed: {
		apps() {
			const apps = this.$store.getters["apps/getApps"]
			return apps;
		}
	},
	methods: {
		showGameDetail(app) {
			let path = this.$route.path + "/" + app.id
			this.$router.push(path)
		}
	}
};
</script>

<style scoped>
ul {
	list-style: none;
	margin: auto;
	padding: 0;
	width: 90%;
}

li {
	cursor: pointer;
	margin: 20px;
}
</style>