import { createStore } from 'vuex';

import appsModule from './apps.js';

const store = createStore({
  modules: {
    apps: appsModule
  }
});

export default store;