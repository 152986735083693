<template>
	<div>
		<img :src="imagePath" />
		<div class="title-content">
			<h1>{{ app.name }}</h1>
			<h3>{{ app.releaseYear }}</h3>
		</div>
		<div class="download-content">
			<button
				v-if="app.appStoreLink"
				class="store-button"
				@click="launchAppStore"
			/>
		</div>
	</div>
	<div class="main-content">
		<h3>Description</h3>
		<p>{{ app.description }}</p>
	</div>
</template>

<script>
export default {
	props: ["id"],
	data() {
		return {
			app: null,
		};
	},
	computed: {
		// Solution for dynamic image based on this post: https://blog.lichter.io/posts/dynamic-images-vue-nuxt/
		imagePath() {
			return require(`../../assets/images/${this.app.iconName}`);
		},
	},
	methods: {
		loadCurrentApp() {
			const apps = this.$store.getters["apps/getApps"];

			for (let i = 0; i < apps.length; i++) {
				if (apps[i].id == this.id) {
					this.app = apps[i];
					return;
				}
			}
		},
		launchAppStore() {
			open(this.app.appStoreLink);
		},
	},
	created() {
		this.loadCurrentApp();
	},
};
</script>

<style scoped>
/* General */

div {
	margin: 20px;
}

/* Top content */

img {
	width: 20%;
	border-radius: 20px;
	display: inline-block;
}

.title-content {
	width: 35%;
	text-align: center;
	vertical-align: top;
	display: inline-block;
}

.title-content > h1,
.title-content > h3 {
	text-align: right;
	margin: 0;
}

.download-content {
	display: inline-block;
	vertical-align: top;
	margin-left: 10%;
}

.store-button {
	cursor: pointer;
	background: url("../../assets/images/download-button.svg");
	background-size: cover;
	width: 160px;
	height: 52px;
	border: none;
}

/* Main content */

.main-content {
	padding: 10px;
	height: 500px;
	background: #e0e0e0;
	border-radius: 20px;
}

.main-content > h3 {
	margin: 0;
	text-align: left;
	color: black;
}
</style>