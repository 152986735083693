<template>
	<base-card @click="$emit('tile-selected', app)">
		<img :src="imagePath" :alt="app.name" />
		<div class="text-section">
			<h2>{{ app.name }}</h2>
			<p>{{ app.description }}</p>
		</div>
	</base-card>
</template> 

<script>
export default {
	props: ["app"],
	emits: ["tile-selected"],
	computed: {
		// Solution for dynamic image based on this post: https://blog.lichter.io/posts/dynamic-images-vue-nuxt/
		imagePath() {
			return require(`../../../assets/images/${this.app.iconName}`);
		}
	},
};
</script>

<style scoped>
.text-section {
	display: inline-block;
	padding: 10px;
	width: 80%;
}

h2 {
	color: black;
	text-align: left;
}

img {
	width: 128px;
	vertical-align: top;
	color: #dde0fe;
	border-radius: 10px;
}
</style>
