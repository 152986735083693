<template>
	<div class="policy-body">
		<h1>Privacy Policy - Game Mate</h1>
		
		<div class="policy-section">
			<h3>Permissions Requested</h3>
			<p>
				The application requests explicit permission from the user before
				accessing Facebook data. Users have the option to deny access,
				though it may impact the game experience.
			</p>
			<p>The following Facebook permissions are requested upon login:</p>
			<ul>
				<li>
					<strong> public_profile: </strong>Allows apps to read the Default
					Public Profile Fields on the User node. This permission is
					automatically granted to all apps.
				</li>
				<li>
					<strong>user_friends: </strong>The user_friends permission allows your
					app to get a list of a person's friends using that app.
				</li>
			</ul>
		</div>

		<div class="policy-section">
			<h3>Data Collection</h3>
			<p>
				Some data is collected to improve the game experience. All
				data is stored using secure Google Firebase rules, and nothing is
				sold. Data is used only within the context of the application.
			</p>
			<p>The following data is collected:</p>
			<ul>
				<li><strong>Facebook Name/ID</strong>. Used invite friends to games.</li>
				<li><strong>Game Scores</strong>. Used to display player scores in games.</li>
			</ul>
		</div>

		<div class="policy-section">
			<h3>Data Deletion</h3>
			<p>
				All user data can be deleted at the request of the user.
				Deleted data is not recoverable. Once a user elects to delete data,
				they will start from scratch when using the application again.
			</p>
			<p>The following methods can be used to delete user data</p>
			<ol>
				<li>
					Send an email on our <strong>Contact Page</strong> requesting data deletion. (Must include email address).
				</li>
			</ol>
		</div>

		<h3>Contact Information</h3>
		<p><strong>Owner and data controller</strong></p>
		<p>Brook Street Games LLC</p>
		<p>1993 Foxglove Circle, Bellport NY 11713</p>
		<p>brookstgames@gmail.com</p>
	</div>
</template>

<style scoped>
* {
	font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif;
	text-align: left;
	color: black;
}

h1 {
	font-size: 32px;
	text-align: center;
}

h3 {
	font-size: 20px;
}

p,
li {
	font-size: 14px;
	color: #444444;
}

.policy-body {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem;
}
</style>